@import 'includes.scss'; 

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body{
    overflow-x:hidden ;
    .header{
        display: flex;
    }
    .db_header{
        display: none;
    }
}





.form_select{
    border: 1px solid #E5EEFF;
    border-radius: 4px;
    width: 100%;
    height: 35px;
    padding: 10px 15px;
    font-family: $primaryfontRegular;
    font-size: 12px;
    line-height: 12px;
    color: $primaryColor;
    outline: none;
    box-shadow: none;
    background: $white;
    background-size: 10px;
    appearance: none;
}

.search_area{
    position: relative;
    margin-left:10px;
    .form_control{
        padding-left: 35px;
    }
    img.search_icon{
        position: absolute;
        left: 15px;
        top:50%;
        transform: translateY(-50%);
    }
}

.form_control{
    border: 2px solid #0059FE4D;
    border-radius: 4px;
    min-width: 180px;
    height: 40px;
    outline: none;
    padding:0 12px 0 12px;
    font-family:$primaryfontRegular;
    font-size:13px; 
    color: $primaryColor;
    box-sizing: border-box;
    &:focus, &:focus-visible{
        outline: none;
        box-shadow: none;
    }
    &.border0{
        border: none;
    }
}

.primary_btn{
    background: $secondaryColor;
    font-family: $primaryfontSemiBold;
    font-size: 12px;
    color: $white;
    border: 1px solid $secondaryColor;
    border-radius: 4px;
    outline: none;
    padding: 0 15px;
    cursor: pointer;
    white-space: nowrap;
    height: 30px;
    display: inline-flex;
    justify-content: center; 
    align-items: center;
    line-height: 30px;
    &:hover{
        background: transparent;
        color: $secondaryColor;
    }
    &:focus, &:focus-visible{
        outline: none;
        box-shadow: none;
    }
    &.add_more{
        min-width: 40px;
        max-width: 40px;
        height: 40px;
        font-size: 25px;
        line-height: 22px;
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 10px 20px #0059FE33;
        &:hover{
            background: $secondaryColor;
        }
    }
}

.secondary_btn{
    background: #F76C7A;
    font-family: $primaryfontSemiBold;
    font-size: 12px;
    color: $white;
    border: 1px solid #F76C7A;
    border-radius: 4px;
    outline: none;
    padding: 0 15px;
    cursor: pointer;
    white-space: nowrap;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 30px;
    &:hover{
        background: transparent;
        color: #F76C7A;
    }
    &:focus, &:focus-visible{
        outline: none;box-shadow: none;
    }
}

.main_content{
    width: calc(100% - 60px);
    left: 60px;
    position: relative;
    // transition: all .3s ease-in-out;
    padding-top: 0px;
    position: relative;
    display: flex;
    align-items: flex-start; 
    transition: all 0.5s ease-in-out;
}

.sidebar_not_collapsed .main_content{
    width: calc(100% - 200px);
    left: 200px;
}

.sidebar_not_collapsed .header{
    width: calc(100% - 200px);
    left: 200px;
}



.profile_icon{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center; 
    overflow: hidden;
    cursor: pointer;
    img{
        width: 100%;
        object-fit: contain;
    }
}

.main_content_left{
    width: 100%;
    position: relative;
    display: flex;
    transition: all 0.5s ease-in-out;
}

.main_content_right {
    
}



.filter_actions{
    position: absolute;
    top: 14px; 
    right: 0;
    display: flex;
    padding-right: 39px;
    li{
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $borderColor;
        border-radius: 6px;
        cursor: pointer;
        padding: 0 10px;
        height: 30px;
        min-width: 60px;
        margin-right: 5px;
        // &:last-child{margin: 0;}
        img{
            display: block;
            &.active{
                display: none;
            }
        }
        // &:not(:first-child){
        //     &.active{
        //         background: #868E9E;
        //         img{
        //             display: none;
        //             &.active{
        //                 display: block;
        //             }
        //         }
        //     }
        // }
        &.filter{
            background: url('/images/actions/filter.svg') no-repeat center center;
            &.active{
                background: #868E9E url('/images/actions/filter_white.svg') no-repeat center center;
            }
        }
        &.funnel{
            background: url('/images/actions/funnel.svg') no-repeat center center;
            &.active{
                background: #868E9E url('/images/actions/funnel_white.svg') no-repeat center center;
            }
        }
        &.eye{
            background: url('/images/actions/eye.svg') no-repeat center center;
            &.active{
                background: #868E9E url('/images/actions/eye_white.svg') no-repeat center center;
            }
        }
        // &.comment{
        //     background: url('/images/actions/comment.svg') no-repeat center center;
        //     &.active{
        //         background: #868E9E url('/images/actions/comment_white.svg') no-repeat center center;
        //     }
        // }
        .mytasks{
            font-family: $primaryfontMedium;
            font-size: 12px;
            color: $primaryColor;
            display: flex;
            align-items: center;
            em{
                background: $primaryColor;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                font-family: $primaryfontSemiBold;
                font-size: 11px;
                border-radius: 4px;
                min-width: 22px;
                height: 22px;
                margin-left: 6px;
                margin-right: -4px;
                font-style: normal;
            }
        }
        // &:last-child{
        //     min-width: 40px;
        //     width: 40px;
        //     border-top-right-radius: 0;
        //     border-bottom-right-radius: 0;
        // }
    }
}

.add_project_sec{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px;
    border-top: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
    .form_control{
        width: 100%;
    }
    .add_actions{
        display: flex;
        align-items: center;
        li{
            display: flex;
            margin-right: 10px;
            position: relative;
            span{
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px dashed rgba($color: $primaryColor, $alpha: 0.7);
                border-radius: 50%;
                min-width: 48px;
                height: 48px;
                cursor: pointer;
            }
            &:last-child{
                margin: 0;
            }
            .dropdown_wrapper{
                min-width: 100px;
                position: absolute;
                right: 0;
                bottom: 100%;
                display: none;
                background: $white;
                box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1019607843);
                border-radius: 4px;
                padding: 10px;
                font-family: $primaryfontRegular;
                font-size: 12px;
                white-space: nowrap;
            }
            &:hover .dropdown_wrapper{
                display: block;
            }
        }
    }
}

.MuiTooltip-popper {
    .MuiTooltip-tooltip {
        background: $white;
        box-shadow: 0px 5px 10px #0000001A;
        border-radius: 4px;
        ul {
            padding: 5px 0;
            li {
                display: flex;
                align-items: center; 
                list-style-type: none;
                padding: 5px;
                min-width: 150px;
                // figure {
                //     width: 28px;
                //     height: 28px;
                //     display: block;
                //     border-radius: 50%;
                //     overflow: hidden;
                //     img {
                //         min-width: 100%;
                //         height: auto;
                //         max-height: 100%;
                //         display: block;
                //     }
                // }
                // span {
                //     width: 28px;
                //     height: 28px;
                //     display: flex;
                //     align-items: center;
                //     justify-content: center;
                //     border-radius: 50%;
                //     overflow: hidden;
                //     background: $base-color;
                //     font-family: $primaryfontSemiBold;
                //     font-size: 10px;
                //     color: $primary-color;
                //     text-transform: uppercase;
                // }
                // label {
                //     font-family: $primaryfontMedium;
                //     font-size: 12px;
                //     color: $primary-color;
                //     padding: 0px 0 0 10px;
                // }
                &:hover {
                    background: #fafafa;
                }
            }
        }
        .MuiTooltip-arrow  {
            display: none;
            &:before {
                background-color: $white;
            }
        }    
        .usericon-details {
            display: block;
            text-align: center;
            padding: 8px 0;
            label {
                font-family: $primaryfontMedium;
                font-size: 14px;
                line-height: 17px;
                color: $primary-color;
                padding: 0px 0 3px 0px;
                display: block;
            }
            p {
                font-family: $primaryfontRegular;
                font-size: 12px;
                color: $primary-color;
                padding: 0px 0 0 0px;
            }
        }    
        .actions-menu {
            ul {
                li {display: flex;
                    min-width: 100px;
                    a {
                        width: 100%;
                        font-family: $primaryfontMedium;
                        font-size: 12px;
                        color: $primaryColor;
                        padding: 0;
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        img{
                            margin-right: 10px;
                            width: 12px;
                        }
                    }
                }
            }
            &.assignee_menu{
                ul li {
                    padding: 10px 5px;
                    &.search_area{
                        padding: 0;
                        margin: -9px -8px 0;
                        border-bottom: 1px solid rgba($color: #3F4C65, $alpha: 0.15);
                        max-width: 220px;
                        .form_control{
                            border: none;
                        }
                    }
                    a{
                        em{
                          min-width: 30px;
                          height: 30px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          border-radius: 50%;
                          background: #E0F8CC;
                          font-family: $primaryfontMedium;
                          font-size: 13px;
                          font-style: normal;
                        }
                        .assignee_avatar{
                          min-width: 30px;
                          height: 30px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          border-radius: 50%;
                          img{
                              width: 100%;
                              object-fit: contain;
                              margin-right: 0;
                          }
                        }
                        .assignee_details{
                          display: flex;
                          flex-direction: column;
                          margin-left: 7px;
                          .assignee_name{
                              font-family: $primaryfontRegular;
                              font-size: 13px;
                              color: $primaryColor;
                          }
                          .assignee_mail{
                              font-family: $primaryfontRegular;
                              font-size: 12px;
                              color:rgba($color: $primaryColor, $alpha: 0.8);
                          }
                        }
                      }
                }
            }
            &.file_list_menu{
                .file_list{
                    display: flex;
                    align-items: center;
                    background: $white;
                    border: 1px solid $borderColor;
                    box-shadow: 0px 1px 1px #E4E8F0;
                    border-radius: 4px;
                    padding: 10px;
                    overflow: hidden;
                    .file_info{
                        display: flex;
                        flex-direction: column;
                        margin-left: 10px;
                        span{
                            font-family: $primaryfontRegular;
                            font-size: 12px;
                            color: $primaryColor;
                            max-width: 117px;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        em{
                            font-family: $primaryfontRegular;
                            font-size: 11px;
                            color: rgba($color: $primaryColor, $alpha: 0.7);
                            font-style: normal;
                        }
                    }
                }
            }
        }
    }   
    &.MuiPopperUnstyled-root[data-popper-placement*="bottom"] .MuiTooltip-tooltip { 
        margin-top: 0px;
        margin-left: -10px;
    } 
    &.MuiPopperUnstyled-root[data-popper-placement*="top"] .MuiTooltip-tooltip {    
        margin-bottom: 0px;
    }
}
 


.pad20 {padding: 20px;}
.padt20 {padding-top: 20px;}
.mb-0 {margin-bottom: 0px !important;}
.d-inline-flex {display: inline-flex !important;}
.align-center {align-items: center !important;}
.w-full {width: 100% !important;}
 
//  /////
// .customselect {
//     border: 1px solid $borderColor;
//     border-radius: 4px;
//     height: 35px;
//     min-width: 120px;
//     background: $white;
//     .MuiSelect-outlined{
//         height: 100%;
//         border-radius: 4px;
//         font-family: $primaryfontMedium;
//         font-size: 12px;
//         color: $primaryColor;
//         background: url('/images/down-arrow.svg') no-repeat right 12px center;
//         background-size: 10px;
//     }
//     .MuiSelect-icon{
//         display: none;
//     }
//     fieldset {
//         display: none;
//     }
//     &.select_client{
//         min-width: 125px;
//     }
//     &.select_channel{
//         min-width: 150px;
//     }
//     &.table_select{
//         border: none;
//         background: transparent;
//         height: auto;
//         .MuiSelect-outlined{
//             background: transparent;
//             padding: 0;
//             font-family: $primaryfontRegular;
//         }
//     }
//     &.db_project_select{
//         border: none;
//         height: auto;
//         .MuiSelect-outlined{
//             padding: 0;
//             font-family: $primaryfontMedium;
//             font-size: 14px !important;
//             color: $primaryColor;
//         }
//         .project_logo{
//             margin-right: 10px;
//             font-size: 14px !important;
//         }
//     }
// }

.custom_select {
    min-width: 110px;
    .custom_select_inner__control {
        border: 1px solid #E5EEFF !important;
        height: 35px;
        min-height: 35px;
        box-shadow: none !important;
        cursor: pointer;
        &:hover {
            border: 1px solid #E5EEFF !important;
        }
        .custom_select_inner__value-container {
            .custom_select_inner__placeholder {
                font-family: $primaryfontRegular;
                color: #112437;
                font-size: 12px;
            }
            .css-1dimb5e-singleValue {
                font-family: $primaryfontRegular;
                color: #112437;
                font-size: 12px;
            }
            .custom_select_inner__input-container input {
                font-family: $primaryfontRegular;
                color: #112437;
                font-size: 12px;
                padding: 0px;
                margin: 0px;
            }
        }
        .custom_select_inner__indicators {
            padding: 0 5px;
            .custom_select_inner__indicator-separator {
                display: none;
            }
            .custom_select_inner__indicator {
                padding: 0;
            }
        }
    }
    // .custom_select_inner__menu{
    //     margin: 1px 0;
    // }
    &.assignee_select{
        width: 145px;
    }
    &.db_project_select{
        .custom_select_inner__control{
            border: none !important;
            height: auto;
            .custom_select_inner__value-container {
                .custom_select_inner__placeholder {
                    font-family: $primaryfontMedium;
                    color: #112437;
                    font-size: 14px;
                }
                .css-1dimb5e-singleValue {
                    font-family: $primaryfontMedium;
                    color: #112437;
                    font-size: 14px;
                }
                .custom_select_inner__input-container input {
                    font-family: $primaryfontMedium;
                    color: #112437;
                    font-size: 14px;
                    padding: 0px;
                    margin: 0px;
                }
            }
        }
        .project_logo{
            margin-right: 10px;
            font-size: 14px !important;
        }
    }
    &.assignee_select{
        .custom_select_inner__control{
            border: none !important;
            height: auto;
            background: transparent;
            .custom_select_inner__value-container {
                padding: 0;
                .custom_select_inner__placeholder {
                    font-family: $primaryfontRegular;
                    color: #112437;
                    font-size: 12px;
                }
                .css-1dimb5e-singleValue {
                    font-family: $primaryfontRegular;
                    color: #112437;
                    font-size: 12px;
                    margin: 0;
                }
                .custom_select_inner__input-container input {
                    font-family: $primaryfontRegular;
                    color: #112437;
                    font-size: 12px;
                    padding: 0px;
                    margin: 0px;
                }
            }
            .custom_select_inner__indicators{
                display: none !important;
            }
        }
    }
}

.MuiMenu-list li{
    font-family: $primaryfontMedium;
    font-size: 12px;
    color: $primaryColor;
    &.cust_pr_drpdwn .project_logo{
        display: none;
    }
}

.custom_check{
    position: relative;
    label{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        width: 100%;
        padding: 0 5px;
        margin: 5px 0;
        border: 1px solid transparent;
        em{
            font-style: normal;
            min-width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $primaryfontMedium;
            font-family: 13px;
            border-radius: 50%;
            background: #E0F8CC;
            margin-right: 7px;
            overflow: hidden;
        }
    }
    input[type=checkbox]{
        display: none;
    }
    input:checked ~ label em{
        font-size: 0;
        background: #2884f0 url('/images/check-mark.svg') no-repeat center center;
        background-size: 17px;
    }
}

.custom_radio{
    position: relative;
    label{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        width: 100%;
        padding: 0 5px;
        margin: 5px 0;
        border: 1px solid transparent;
        em{
            font-style: normal;
            min-width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $primaryfontMedium;
            font-family: 13px;
            border-radius: 50%;
            background: $white;
            border: 2px solid #d5d5d9;
            margin-right: 7px;
            overflow: hidden;
        }
    }
    input[type=radio]{
        display: none;
    }
    input:checked ~ label em{
        font-size: 0;
        background: #2884f0 url('/images/check-mark.svg') no-repeat center center;
        background-size: 17px;
        border: 2px solid #2884f0;
    }
}

.more_menu span{
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
}