@import 'includes.scss';

.header{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    height:60px;
    border-bottom:1px solid $borderColor;
    // transition: all .3s ease-in-out;
    position: sticky;
    top: 0;
    width: calc(100% - 60px);
    left: 60px;
    z-index: 9;
    background:$white;
    .menu_icon{
        display: none;
    }
    .left_cnt{
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .right_cnt{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 20px;
    }
    .project_dropdown{
        display: flex;
        align-items: center;
        padding: 0 20px;
        border-right: 1px solid $borderColor;
        height: 100%;
        cursor: pointer;
        .project_logo{
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 36px;
            height: 36px;
            border-radius: 10px;
            font-family: $primaryfontBold;
            color: $white;
            font-size: 16px;
            background: #E64254;
        }
        .project_desc{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding-left: 10px;
            .heading{
                font-family: $primaryfontMedium;
                color: rgba($color: $secondaryColor, $alpha: 0.8);
                font-size: 11px;
            }
            .list{
                font-family: $primaryfontMedium;
                color:$primaryColor;
                font-size: 14px;
                width: 120px;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .more{
            min-width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $primaryfontSemiBold;
            font-size: 11px;
            color: $primaryColor;
            background: $white;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 50%;
            margin-left: 20px;
        }
        img.down-arrow{
            margin-left: 12px;
        }
        &.open{
            box-shadow: 0px 0px 25px #0516360D;
            border-right: 1px solid transparent;
            z-index: 1;
        }
    }

    .project_dropdown_cnt{
        position: absolute;
        top: 100%;
        width: 100%;
        background: $white;
        box-shadow: 0px 0px 25px #0516360D;
        padding: 25px 20px 20px;
        > ul{
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            > li{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                border: 1px solid #05163633;
                border-radius: 4px;
                box-shadow: 0px 10px 20px #0516360D;
                max-width: 280px;
                position: relative;
                margin: 0 30px 20px 0;
                &.active{
                    border: 1px solid #65DB00;
                }
                .top_sec{
                    padding: 20px 20px 15px;
                    background: $white;
                    border-radius: 4px 4px 0 0;
                    .project_header{
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        .project_logo{
                            min-width: 40px;
                            height: 40px;
                            background:#E64254;
                            border-radius: 4px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-family: $primaryfontBold;
                            font-size: 14px;
                            font-weight: 800;
                            color: $white;
                            &.PF{
                                background: #102786;
                            }
                            &.QX{
                                background: #000000;
                            }
                            &.RM{
                                background: #2ACB6E;
                            }
                        }
                        .project_name{
                            font-family: $primaryfontBold;
                            font-size: 18px;
                            color: $primaryColor;
                            margin-left: 10px;
                        }
                    }
                    .projects_task_details{
                        margin-top: 15px;
                       > ul {
                            display: flex;
                            flex-wrap: wrap;
                           > li{
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: center;
                                padding: 0 10px;
                                height: 30px;
                                margin: 0 7px 7px 0;
                                border-radius: 4px;
                                span{
                                    font-family: $primaryfontMedium;
                                    font-size: 12px;
                                    color: $primaryColor;
                                    line-height: normal;
                                }
                                em{
                                    font-family: $primaryfontSemiBold;
                                    font-size: 13px;
                                    font-style: normal;
                                    color: $primaryColor;
                                    margin-left: 7px;
                                    line-height: normal;
                                }
                                &.all{
                                    background: #E5EEFF;
                                }
                                &.new{
                                    background: #DFFFC4;
                                }
                                &.overdue{
                                    background: #FFD6D6;
                                }
                                &.progress{
                                    background: #FFF2C6;
                                }
                                &.hold{
                                    background: #0516361A;
                                }
                            }
                        }
                    }
                }
                .assignee_list{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: #F5F8FF;
                    border-radius: 0 0 4px 4px;
                    padding: 15px 20px;
                    width: 100%;
                    ul{
                        display: flex;
                        align-items: center;
                        li{
                            display: flex;
                            align-items: center;
                            .assignee_avatar{
                                min-width: 36px;
                                height: 36px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: 2px solid $white;
                                border-radius: 50%;
                                img{
                                    width: 100%;
                                    object-fit: contain;
                                }
                            }
                            &:nth-child(2), &:nth-child(3){
                                margin-left: -7px;
                            }
                        }
                        .more{
                            margin-left: 10px;
                            font-family: $primaryfontSemiBold;
                            color: $primaryColor;
                            font-size: 16px;
                            cursor: pointer;
                        }
                    }
                }
                .channels{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    em{
                        font-family: $primaryfontSemiBold;
                        color: $primaryColor;
                        font-size: 16px;
                        font-style: normal;
                    }
                    span{
                        font-family: $primaryfontSemiBold;
                        color: #8348CC;
                        font-size: 12px;
                    }
                }
                .check_btn{
                    position: absolute;
                    right: 15px;
                    top: 15px;
                }
            }
        }
        .check_btn{
            cursor: pointer;
            font-weight: 500;
            position: relative;
            overflow: hidden;
            display: inline-block;
            input{
                position: absolute;
                left: -9999px;
            }
            span{
                display: flex;
                align-items: center;
                border-radius: 50%;
                color: #65DB00;
                &:before{
                    display: flex;
                    flex-shrink: 0;
                    content: "";
                    background-color: #fff;
                    width: 1.5em;
                    height: 1.5em;
                    border-radius: 50%;
                    box-shadow: inset 0 0 0 0.125em #05163634;
                }
            }
            input:checked + span:before{
                box-shadow: 0px 3px 6px #65DB0033;
                background: #65DB00 url('/images/check-mark.svg') no-repeat center center;
                background-size: 80%;
            }
        }
    }

    .kanban_timeline_list ul{
        display: flex;
        align-items: center;
        margin-left:20px;
        li{
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $primaryfontMedium;
            color: $primaryColor;
            font-size: 13px;
            height: 26px;
            line-height: 20px;
            margin-right:20px;
            cursor: pointer;
            &:last-child{
                margin-right: 0;
            }
            &.active{
                font-family: $primaryfontSemiBold;
                background: #E5EEFF;
                padding:0 18px;
                border-radius: 4px;
            }
        }
    }
    .message_notification{
        margin-left: 20px;
        ul{
            display: flex;
            align-items: center;
            li{
                display: flex;
                align-items: center;
                margin-right: 20px;
                cursor: pointer;
            }
        }
    }
}