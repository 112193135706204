@import 'includes.scss';  


.table_responsive{
    margin-top: 20px;
    .table_header{
        display: inline-flex;
        width: auto;
        background: #F7F7F7;
        height: 40px;
        padding: 0 15px;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 20px;
        span{
            display: flex;
        }
        .project_cnt{
            display: flex;
            align-items: center
            ;cursor: pointer;
            img{
                margin-right: 10px;
                transition: all 0.3s ease-in-out;
                transform: rotate(-90deg);
                &.collapsed{
                    transform: rotate(0deg);
                }
            }
        }
        .project_logo{
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 24px;
            height: 24px;
            border-radius: 10px;
            overflow: hidden;
            em{
                font-family: $primaryfontBold;
                color: $white;
                font-size: 11px;
                font-style: normal;
                background: #E64254;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            img{
                max-width: 20px;
                margin: 0;
                transform: none;
            }
        }
        .project_name{
            font-family: $primaryfontSemiBold;
            color: $primaryColor;
            font-size: 14px;
            margin-left: 6px;
        }
        .more{
            margin-left: 10px;
            cursor: pointer;
            img{
                cursor: pointer;
                padding: 5px 0;
            };
        }
    }
    .projects_table{
        width: 100%;
        border-collapse: collapse;
        display: table;
        &.collapsed{
            display: none;
        }
        thead tr{
            background: #F7F7F7;
            height: 40px;
            border-top: 1px solid $borderColor;
            th{
                font-family: $primaryfontRegular;
                font-size: 12px;
                color: $primaryColor;
                text-align: left;
                padding: 10px;
                &:first-child{
                    padding-left: 35px;
                }
            }
        }
        tbody tr{
            background: $white;
            border-top: 1px solid $borderColor;
            &.open_head{
                border: none;
            }
            &:nth-last-child(1){
                border-bottom: 1px solid $borderColor;
            }
            &:nth-child(even){
                background: #F7F7F7;
            }
            &.bg_white{
                background: $white;
            }
            td{
                font-family: $primaryfontRegular;
                font-size: 12px;
                color: $primaryColor;
                text-align: left;
                padding: 10px;
                input{
                    border: none;
                    outline: none;
                    padding: 5px;
                    font-family: $primaryfontRegular;
                    font-size: 12px;
                    width: 100%;
                    background: transparent;
                    &:focus{
                        outline: 1px solid $borderColor;
                    }
                }
                &.date_column input{
                    max-width: 105px;
                }
                &:first-child{
                    padding-left: 35px;
                    font-size: 14px;
                    input{
                        font-size: 14px;
                    }
                }
                span{
                    &.assignee{display: flex;align-items: center;
                        em{display: flex;align-items: center;justify-content: center;min-width: 24px;height: 24px;border-radius: 50%;
                            font-style: normal; margin-right: 6px;background: #65DB0033;font-family: $primaryfontMedium; 
                            font-size: 12px; color: rgba($color: $primaryColor, $alpha: 0.8);
                            &.bg_light_green{
                                background: #65DB0033;
                            }
                            &.bg_light_blue{
                                background: #0059FE33;
                            }
                            &.bg_light_pink{
                                background: #FFE0E0;
                            }
                        }
                    }
                    &.priority{
                        padding-left: 15px;
                        position: relative;
                        text-transform: capitalize;
                        &.high{
                            color: #FC6262;
                            background: url('/images/high_arrow.svg') no-repeat left center;
                            .MuiSelect-outlined{
                                color: #FC6262;
                            }
                        }
                        &.normal{
                            color: #387DFF;
                            .MuiSelect-outlined{
                                color: #387DFF;
                            }
                            &:before{
                                content: '';
                                width: 8px;
                                height: 2px;
                                background: #387DFF;
                                position: absolute;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                        &.low{
                            color: #EA8612;
                            background: url('/images/low_arrow.svg') no-repeat left top 5px;
                            .MuiSelect-outlined{
                                color: #EA8612;
                            }
                        }
                    }
                    &.status{
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        min-width: 86px;
                        max-width: 86px;
                        white-space: nowrap;
                        height: 24px;
                        font-family: $primaryfontMedium;
                        font-size: 12px;
                        padding: 0 10px;
                        border-radius: 4px;
                        &.progress{
                            background: #FFFAD3;
                            color: #6E651D;
                            .customselect.table_select .MuiSelect-outlined{
                                color: #6E651D;
                            }
                        }
                        &.overdue{
                            background: #FFE0E0;
                            color: #895656;
                            .customselect.table_select .MuiSelect-outlined{
                                color: #895656;
                            }
                        }
                        &.completed{
                            background: #E7FFD3;
                            color: #528625;
                            .customselect.table_select .MuiSelect-outlined{
                                color: #528625;
                            }
                        }
                        .customselect.table_select .MuiSelect-outlined{
                            justify-content: center;
                            padding: 0;
                            margin: 0;
                            font-family: $primaryfontMedium;
                        }
                    }
                }
            }
            &.collapsed{
                display: none;
            }
        }
        .collapsing_header{
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-left: -20px;
            span{
                display: flex;
                cursor: pointer;
                &.move_row{margin-right: 18px;cursor: move;}
                &.task_heading{font-family: $primaryfontMedium;font-size: 14px;color: $primaryColor;
                    img{margin-right: 10px;
                        transition: all 0.3s ease-in-out;
                        transform: rotate(-90deg);
                        &.collapsed{
                            transform: rotate(0deg);
                        }
                    }
                }
                &.more{margin-left: 10px;
                    img{
                        padding: 5px 0;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .project_status{
        margin-left: 26px;
        ul{
            padding: 0;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            li{
                display: flex;
                align-items: center;
                font-family: $primaryfontRegular;
                font-size: 13px;
                color: $primaryColor;
                padding: 0 0 0 15px;
                margin: 0 20px 0 0;
                position: relative;
                &:after{
                    content: '';
                    width: 9px;
                    height: 9px;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    border-radius: 50%;
                }
                em{
                    font-style: normal;
                    font-family: $primaryfontSemiBold;
                    padding-right: 5px;
                }
                &.new:after{
                    background: $secondaryColor;
                }
                &.completed:after{
                    background: #65DB00;
                }
                &.progress:after{
                    background: #FDC200;
                }
                &.overdue:after{
                    background: #FB3D52;
                }
                &.closed:after{
                    background: #AEBDD8;
                }
                &.delayed:after{
                    background: #9BA6BA;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
}

.projects_table  {
    .autofocus:focus {
        outline:1px solid #E4E8F0;
        background: $white;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }
    .Mui-focused input.MuiSelect-nativeInput  {
        outline:1px solid #E4E8F0;
        background: $white;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }
}

