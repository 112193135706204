$primaryColor: #112437;
$secondaryColor: #0859FE;
$activeColor: #65DB00;
$borderColor: #E4E8F0;
$white: #FFFFFF;
$black: #000000;

$primaryfontBold: 'InterBold';
$primaryfontSemiBold: 'InterSemiBold'; 
$primaryfontMedium: 'InterMedium';
$primaryfontRegular: 'InterRegular';
$primaryfontLight: 'InterLight';


.tabs_wrapper{ 
    // position: fixed;right: 0;top:60px;
    background: $white;width: 330px;height: 100%;border-left: 1px solid $borderColor;
    .tablist{background: $white;border-bottom: 1px solid $borderColor;height: 60px;display: flex;align-items: center;
        justify-content: space-between;width: 100%;
        .tab_btn{display: flex;align-items: center;justify-content: center;background: $white;border: none;outline: none;
            width: 50%;height: 100%;cursor: pointer;font-family: $primaryfontRegular;font-size: 16px;color: $primaryColor;position: relative;
            .count{min-width: 24px;height: 24px;display: flex;align-items: center;justify-content: center;font-family: $primaryfontSemiBold;
                font-size: 11px;color: $primaryColor;background: $white;border: 1px solid $borderColor;border-radius: 50%;margin-left: 10px;}
            &.active{color: $activeColor;
                &:after{content: '';width: 100%;height: 2px;background: $activeColor;position: absolute;left: 0;bottom: -1px;
                }
            }
        }
    }
    .tab_cnt{
        .filter_sec{background: rgba($color: #EFEFEF, $alpha: 0.5);min-height: 60px;padding: 12px 20px;display: flex;align-items: center;
            justify-content: space-between; 
            .all_select, .client_select{width: 120px;}
            .channels_select{width: 150px;}
            .custom_select {width: calc(50% - 5px);}
        }
    }
}

.activities_list{  
    .activity_item{
        padding: 20px;
        .activity_heading{display: flex;align-items: center;justify-content: space-between;
            .left_cnt{display: flex;align-items: center;justify-content: flex-start;
                .profile_icon{
                    width: 30px;
                    height: 30px;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        width: 100%;
                        object-fit: contain;
                    }
                    span{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-family: $primaryfontBold;
                        color: $white;
                        font-size: 12px;
                        &.IB{
                            background: #E64254;
                        }
                        &.PF{
                            background: #102786;
                        }
                        &.RM{
                            background: #2ACB6E;
                        }
                    }
                }
                h6{font-family: $primaryfontMedium;font-size: 14px;color: $primaryColor;
                    margin-left: 10px;font-weight: 500;
                    span{
                        color: #0059FE;
                    }
                }
            }
            .right_cnt{display: flex;align-items: center;justify-content: flex-end;
                .time{font-family: $primaryfontRegular;font-size: 11px;color: $primaryColor;margin-right: 10px;}
            }
        }
        .activity_desc{padding: 15px;background: #F7F7F7;border-radius: 4px;margin-top: 5px;position: relative;
            h6{font-size: 14px;font-family: $primaryfontRegular;font-weight: 500;color: $primaryColor;line-height: 16px;
                span{
                    color: #0059FE;
                }
                &.small{
                    font-size: 13px;
                }
            }
            p{font-size: 13px;font-family: $primaryfontRegular;margin-top: 5px;color: rgba($color: $primaryColor, $alpha: 0.8);
            }
            button{margin-top: 10px;}
            .file_sec{
                display: flex;
                margin-top: 12px;
                .file_list{
                    display: flex;
                    align-items: center;
                    background: $white;
                    border: 1px solid $borderColor;
                    box-shadow: 0px 1px 1px #E4E8F0;
                    border-radius: 4px;
                    padding: 10px;
                    overflow: hidden;
                    .file_info{
                        display: flex;
                        flex-direction: column;
                        margin-left: 10px;
                        span{
                            font-family: $primaryfontRegular;
                            font-size: 12px;
                            color: $primaryColor;
                            max-width: 117px;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        em{
                            font-family: $primaryfontRegular;
                            font-size: 11px;
                            color: rgba($color: $primaryColor, $alpha: 0.7);
                            font-style: normal;
                        }
                    }
                }
                .more_files{
                    display: flex;
                    align-items: center;
                    background: $white;
                    border: 1px solid $borderColor;
                    box-shadow: 0px 1px 1px #E4E8F0;
                    border-radius: 4px;
                    padding: 10px;
                    margin-left: 10px;
                    cursor: pointer;
                    font-family: $primaryfontMedium;
                    font-size: 14px;
                    color: rgba($color: $primaryColor, $alpha: 0.8);
                }
            }
            .time-helptext{
                font-size: 11px;
                font-family: $primaryfontRegular;
                color: rgba($color: $primaryColor, $alpha: 0.7);
                position: absolute;
                right: 0;
                bottom: -15px;
            }
        }
    }
    .timeline-day{position: relative;display: flex;align-items: center;justify-content: center;
        span{display: flex;align-items: center;justify-content: center;height: 22px;padding: 0 15px;border: 1px solid $borderColor;
            font-family: $primaryfontMedium;font-size: 11px;border-radius: 12px; width: auto;background: $white;z-index: 1;
        }
        &:before{content: '';height: 1px;width: 100%;background: $borderColor;position: absolute;top: 50%;transform: translateY(-50%);left: 0;
        }
    }
}


.right-side-nav {
    position: absolute;
    top: 60px;
    right: -330px;
    transition: all 0.5s ease-in-out;
    .activity_tabs {
        position: sticky;
        top: 60px;
    }
    .menu_btn{
        background: url('/images/actions/comment.svg') no-repeat center center;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $borderColor;
        border-right: 0px;
        border-radius: 6px 0 0 6px;
        cursor: pointer;
        padding: 0 10px;
        height: 30px;
        width: 40px; 
        position: absolute;
        right: 100%;
        top: -46px;
        &.active{
            background: #868E9E url('/images/actions/comment_white.svg') no-repeat center center;
            top: 14px;
        }
    }
    .tabs_wrapper {
        .react-tabs {
            ul.react-tabs__tab-list {
                display: flex;
                justify-content: space-between;
                li {
                    list-style-type: none;
                    position: relative;
                    cursor: pointer;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50%;
                    &:focus-visible{
                        outline: none !important;
                    }
                    p{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: $white;                         
                        font-family: $primaryfontRegular;
                        font-size: 16px;
                        color: $primaryColor;
                        position: relative;
                        .count{
                            min-width: 24px;
                            height: 24px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-family: $primaryfontSemiBold;
                            font-size: 11px;
                            color: $primaryColor;
                            background: $white;
                            border: 1px solid $borderColor;
                            border-radius: 50%;
                            margin-left: 10px;
                        }
                    }
                    &.react-tabs__tab--selected{color: $activeColor;
                        &:after{
                            content: '';
                            width: 100%;
                            height: 4px;
                            background: $activeColor;
                            position: absolute;
                            left: 0;
                            bottom: -1px;
                            border-radius: 4px;
                        }
                    }
                }
            }
            .react-tabs__tab-panel {

            }
        }
    }
    .filter_sec{background: rgba($color: #EFEFEF, $alpha: 0.5);min-height: 60px;padding: 12px 20px;display: flex;align-items: center;
        justify-content: space-between;
        .all_select, .client_select{width: 120px;}
        .channels_select{width: 150px;}
        .custom_select {min-width: calc(50% - 5px);}
    }
}


.MuiInputBase-colorPrimary {
    .MuiSelect-select {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 40px 0 10px;
        background: $white;
    }
}


body.right-menu-open {
    .main_content { 
        .main_content_left {
            width: calc(100% - 330px);
        }
        .right-side-nav { 
            right: 0px;
            position: fixed;
            top: 60px;
        }
    }
}