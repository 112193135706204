@import '../includes.scss'; 

.form-group {
    display: block;
    margin-bottom: 20px;
    // .form-label {
    //     display: block;
    //     font-size: 12px;
    //     line-height: 15px;
    //     padding-bottom: 5px;
    //     color: $primary-color;
    //     font-family: $primaryfontMedium;
    // }
    // .form-input {
    //     display: block;
    //     font-size: 14px;
    //     color: $primary-color;
    //     font-family: $primaryfontMedium; 
    //     @include inputborder; 
    //     width: 100%;
    //     height: 40px;
    //     border-radius: 6px;
    //     padding: 0 15px;
    //     &:focus-visible {
    //        outline-color: $primary-color; 
    //     }
    // }

    .MuiFormControl-root {
        padding: 0px;
        margin: 0;
        width: 100%;
        .MuiFormHelperText-root{
            position: absolute;
            right: 0;
            margin: 0;
            font-family: $primaryfontRegular;
            font-size: 12px;
            color: rgba($color: $primary-color, $alpha: 0.6);
        }
        label {
            position: static;
            display: block;
            font-size: 12px;
            line-height: 15px;
            padding-bottom: 5px;
            color: $primary-color;
            font-family: $primaryfontRegular;
            transform: inherit;
        }        
        .MuiOutlinedInput-root  {
            max-height: 40px;
            line-height: 40px;
            .MuiInputBase-input {
                display: flex;
                align-items: center;
                font-size: 14px;
                color: $primary-color;
                font-family: $primaryfontRegular; 
                @include inputborder; 
                width: 100%;
                height: 38px;
                max-height: 38px;
                border-radius: 6px;
                padding: 0 15px;
                background: $white;
                padding-right: 35px;
                &:focus-visible {
                    outline-color: $primary-color; 
                }
                
        &::placeholder{
            color: #1A1818;
            opacity: 1;
            opacity: 1;
            -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
        }
            }
        }
        fieldset {
            display: none;
        }
    }
    &.group-box {
        display: flex;
        .MuiFormControl-root {
            &:nth-child(1) {
                width: 40%;
                margin-right: -1px;
                .MuiOutlinedInput-root .MuiInputBase-input {
                    border-radius: 6px 0 0 6px;
                } 
            }
            &:nth-child(2) {
                width: 60%;
                .MuiOutlinedInput-root .MuiInputBase-input {
                    border-radius: 0 6px 6px 0;
                    
                } 
            }
        }
    }
    &.input_group{
        display: flex;
        align-items: center;
        position: relative;
        .MuiFormControl-root .MuiOutlinedInput-root{
            position: relative;
            .MuiInputBase-input{
                max-width: 30px;
                border-right: none;
                border-top-right-radius: 0;
                border-bottom-right-radius:0;
                padding: 0 10px;
                text-align: center;
            }
            &:after{
                content: 'days';
                background: #FAFAFA;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 70px;
                height: 38px;
                @include inputborder;
                border-left: none;
                border-top-right-radius: 6px;
                border-bottom-right-radius:6px;
                font-family: $primaryfontRegular;
                font-size: 14px;
                color: rgba($color: $primary-color, $alpha: 0.8);
            }
        }
    }
}

.MuiMenu-list{
    padding: 10px 0 !important;
    li{
        font-family: $primaryfontRegular;
        font-size: 14px;
        color: rgba($color: $primary-color, $alpha: 0.7);
        &.Mui-selected{
            font-family: $primaryfontMedium;
            color: $primary-color;
        }
        &:hover{
            color: $primary-color;
        }
    }
}


 