@import 'includes.scss';

.db_wrapper{
    padding:0 20px 20px 20px;
    width: 100%;
    .db_header{
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        height: 60px;
        transition: all .3s ease-in-out;
        position: sticky;
        top: 0;
        width: 100%;
        left: 0px;
        z-index: 9;
        background:$white;
        padding: 30px 20px 15px;
        .menu_icon{
            display: none;
        }
        .left_cnt{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .greeting_text{
                display: flex;
                align-items: center;
                span{
                    font-family: $primaryfontLight;
                    color: $primaryColor;
                    font-size: 30px;
                    margin-right: 7px;
                    em{
                        color: $secondaryColor;
                        font-style: normal;
                    }
                }
            }
        }
        .right_cnt{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .search_area .form_control{
                min-width: 285px;
            }
        }
        .message_notification{
            margin-left: 20px;
            ul{
                display: flex;
                align-items: center;
                li{
                    display: flex;
                    align-items: center;
                    margin-right: 20px;
                    cursor: pointer;
                }
            }
        }
    }
}

.widget{
    border: 1px solid $borderColor;
    border-radius: 6px;
    margin-bottom: 20px;
    .header_sec{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        border-bottom: 1px solid $borderColor;
        .project_dropdown{
            display: flex;
            align-items: center;
            height: 100%;
            cursor: pointer;
            .project_logo{
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 35px;
                height: 35px;
                border-radius: 50%;
                font-family: $primaryfontBold;
                color: $white;
                font-size: 16px;
                background: #E64254;
                &.IB{
                    background: #E64254;
                }
                &.PF{
                    background:#102786;
                }
                &.QX{
                    background: #000000;
                }
            }
            .project_desc{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                padding-left: 10px;
                .heading{
                    font-family: $primaryfontMedium;
                    color: rgba($color: $secondaryColor, $alpha: 0.8);
                    font-size: 11px;
                }
                .list{
                    font-family: $primaryfontMedium;
                    color:$primaryColor;
                    font-size: 14px;
                    max-width: 120px;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            img.down-arrow{
                margin-left: 12px;
            }
        }
        .header_actions{
            display: flex;
            align-items: center;
            .filters{
                display: flex;
                padding: 0;
                margin: 0;
                border: 1px solid $borderColor;
                border-radius: 4px;
                margin-left: 15px;
                li{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: $primaryfontMedium;
                    font-size: 12px;
                    color: $primaryColor;
                    background: $white;
                    padding: 9px 18px;
                    cursor: pointer;
                    &.active{
                        background: #F6F7F8;
                    }
                    &:first-child{
                        border-radius: 4px 0 0 4px;
                    }
                    &:last-child{
                        border-radius: 0 4px 4px 0;
                    }
                }
            }
        }
        h6{
            display: flex;
            align-items: center;
            font-family: $primaryfontMedium;
            font-size: 16px;
            font-weight: inherit;
            img{
                margin-left: 10px;
                cursor: pointer;
            }
        }
        .project_status{
            ul{
                padding: 0;
                margin: 0;
                display: flex;
                flex-wrap: wrap;
                li{
                    display: flex;
                    align-items: center;
                    font-family: $primaryfontRegular;
                    font-size: 13px;
                    color: $primaryColor;
                    padding: 0 0 0 15px;
                    margin: 0 20px 0 0;
                    position: relative;
                    &:after{
                        content: '';
                        width: 9px;
                        height: 9px;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        border-radius: 50%;
                    }
                    em{
                        font-style: normal;
                        font-family: $primaryfontSemiBold;
                        padding-right: 5px;
                    }
                    &.new:after{
                        background: $secondaryColor;
                    }
                    &.completed:after{
                        background: #65DB00;
                    }
                    &.progress:after{
                        background: #FDC200;
                    }
                    &.overdue:after{
                        background: #FB3D52;
                    }
                    &.closed:after{
                        background: #AEBDD8;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .task_sec{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 260px;
        overflow: hidden;
        .task_summary{
            width: 60%;
            display: flex;
            align-items: center;
            justify-content: center;
            .chart_area{
                display: flex;
                flex-direction: column;
                align-items: center;
                p{
                    font-family: $primaryfontMedium;
                    font-size: 14px;
                    color: $primaryColor;
                    margin-bottom: 20px;
                }
                .chart{
                    width: 170px;
                    height: 170px;
                    background: $white;
                    border-radius: 50%;
                    position: relative;
                    border: 5px solid #AEBDD8;
                    .chart_count{
                        width: 110px;
                        height: 110px;
                        background: $white;
                        border-radius: 50%;
                        box-shadow: 0px 10px 20px #0516361A;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        .count{
                            font-family: $primaryfontSemiBold;
                            font-size: 26px;
                            color: $secondaryColor;
                            line-height: 30px;
                        }
                        .total{
                            font-family: $primaryfontMedium;
                            font-size: 14px;
                            color: rgba($color: $primaryColor, $alpha: 0.7);
                        }
                    }
                }
            }
            .chart_labels{
                margin: 35px 0 0 30px;
                ul{
                    padding: 0;
                    margin: 0;
                    li{
                        display: flex;
                        align-items: center;
                        font-family: $primaryfontRegular;
                        font-size: 13px;
                        color: $primaryColor;
                        padding: 0 0 0 15px;
                        margin-bottom: 10px;
                        position: relative;
                        &:last-child{
                            margin: 0;
                        }
                        &:after{
                            content: '';
                            width: 9px;
                            height: 9px;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            border-radius: 50%;
                        }
                        em{
                            font-style: normal;
                            font-family: $primaryfontSemiBold;
                            padding-right: 5px;
                            font-size: 16px;
                        }
                        &.completed:after{
                            background: #65DB00;
                        }
                        &.progress:after{
                            background: #FDC200;
                        }
                        &.overdue:after{
                            background: #FB3D52;
                        }
                        &.closed:after{
                            background: #AEBDD8;
                        }
                    }
                }
            }
        }
        .projects_list{
            width: 40% !important;
            height: 100%;
            position: relative;
            &:before{
                content: '';
                width: 1px;
                height: 100%;
                background: $borderColor;
                position: absolute;
                left: 27.5px;
                top: 0;
            }
             ul {
                padding: 0;
                margin: 0;
                overflow: auto;
                height: 100%;
                 li{
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    padding: 25px 0 15px;
                    position: relative;
                    .project_logo{
                        min-width: 55px;
                        height: 55px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid $borderColor;
                        border-radius: 50%;
                        background: $white;
                        z-index: 1;
                    }
                    .project_status{
                        margin-left: 20px;
                        .project_name{
                            font-family: $primaryfontMedium;
                            font-size: 14px;
                            color: $primaryColor;
                            margin-top: 10px;
                            display: block;
                        }
                        ul{
                            padding: 0;
                            margin: 0;
                            display: flex;
                            flex-wrap: wrap;
                            max-width: 340px;
                            li{
                                display: flex;
                                align-items: center;
                                font-family: $primaryfontRegular;
                                font-size: 13px;
                                color: $primaryColor;
                                padding: 0 0 0 15px;
                                margin: 10px 25px 0 0;
                                position: relative;
                                &:after{
                                    content: '';
                                    width: 9px;
                                    height: 9px;
                                    position: absolute;
                                    left: 0;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    border-radius: 50%;
                                }
                                em{
                                    font-style: normal;
                                    font-family: $primaryfontSemiBold;
                                    padding-right: 5px;
                                }
                                &.completed:after{
                                    background: #65DB00;
                                }
                                &.progress:after{
                                    background: #FDC200;
                                }
                                &.overdue:after{
                                    background: #FB3D52;
                                }
                                &.closed:after{
                                    background: #AEBDD8;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.team_task_sec{
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            max-height: unset;
        }
        .summary-dates{
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            width: 100%;
            border-bottom: 1px solid $borderColor;
            min-height: 32px;
            * {
                -webkit-user-select: none; /* Safari */
                -ms-user-select: none; /* IE 10 and IE 11 */
                user-select: none; /* Standard syntax */
            }
            .arrow{
                padding: 0 12px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &.left{
                    border-right: 1px solid $borderColor;
                    img{
                        transform: rotate(180deg);
                    }
                }
                &.right{
                    border-left: 1px solid $borderColor;
                }
            }
            ul{
                display: flex;
                padding: 0;
                margin: 0;
                li{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 106px;
                    font-family: $primaryfontMedium;
                    font-size: 12px;
                    color: $primaryColor;
                    cursor: pointer;
                    &.active, &:hover{
                        background: $secondaryColor;
                        color: $white;
                    }
                }
            }
            .swiper {
                width: 100%;
                padding: 0 30px;
                position: relative;
                &::after, &::before {
                    content: '';
                    position: absolute;                     
                    top: 0px;
                    width: 1px ;
                    height: 100%;
                    background: #E4E8F0;
                }
                &::after { 
                    right: 30px; 
                }
                &::before { 
                    left: 30px; 
                }
                .swiper-wrapper { 
                    .swiper-slide  {
                        width: 110px; 
                        font-family: $primaryfontMedium;
                        font-size: 12px;
                        color: $primaryColor;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        &.active{
                            background: $secondaryColor;
                            color: $white;
                        }
                    }
                }
                .swiper-button-next, .swiper-button-prev {                     
                    height: 31px;
                    width: 30px;
                    margin: 0;
                    top: 0px;
                    background-color: $white;
                    display: flex;
                    &:after {
                        color: $primaryColor ;
                        font-size: 20px;
                    }
                    &.swiper-button-disabled {
                        opacity: 1;
                        &:after {
                            opacity: 0.35;
                        }
                    }
                }
                .swiper-button-next {
                    right: 0px;
                    background: $white url('../../images/right.svg') no-repeat center center;
                    &:after{
                        display: none;
                    }                    
                }
                .swiper-button-prev  {
                    left: 0px; 
                    background: $white url('../../images/right.svg') no-repeat center center;
                    transform: rotate(180deg);
                    &:after{
                        display: none;
                    }                     
                }
            }
        }
        .team_list_sec{
            padding: 15px 20px;
            width: 100%;
            max-height: 250px;
            overflow: auto;
            .team_member_row{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 15px 0;
                .member_details{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .member_avatar{
                        min-width: 30px;
                        height: 30px;
                        overflow: hidden;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                        img{
                            width: 100%;
                            object-fit: contain;
                        }
                    }
                    .member_name {
                        font-family: $primaryfontMedium;
                        font-size: 12px;
                        color: $primaryColor;
                        margin-left: 5px;
                        width: 75px;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                .projects_status{
                    padding-left: 20px;
                    width: 100%;
                    position: relative;
                    &:before{
                        content: '';
                        width: 10px;
                        height: 10px;
                        background: #B7BCC2;
                        border-radius: 50%;
                        position: absolute;
                        left: 0px;
                        top: 50%;
                        transform: translateY(-50%);
                        opacity: 0.9;
                    }
                    &:after{
                        content: '';
                        height: 0;
                        width: 100%;
                        border-top: 2px dashed #B7BCC2;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        opacity: 0.3;
                    }
                    ul{
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        li{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 30px;
                            border-radius: 4px;
                            font-family: $primaryfontMedium;
                            font-size: 14px;
                            color: $primaryColor;
                            z-index: 1;
                            margin-right: 10px;
                            &:last-child{
                                margin: 0;
                            }
                            &.new{
                                background: #E9F3FF;
                            }
                            &.completed{
                                background: #E4FFCC;
                            }
                            &.progress{
                                background: #FFF4D1;
                            }
                            &.overdue{
                                background: #FFECED;
                            }
                            &.closed{
                                background: #E9F3FF;
                            }
                        }
                    }
                }
                &:nth-child(1){
                    .projects_status ul li{
                        &:nth-child(1){
                            width: 60px;
                        }
                        &:nth-child(2){
                            width: 200px;
                        }
                        &:nth-child(3){
                            width: 200px;
                        }
                        &:nth-child(4){
                            width: 100px;
                        }
                    }
                }
                &:nth-child(2){
                    .projects_status ul li{
                        &:nth-child(1){
                            width: 142px;
                        }
                        &:nth-child(2){
                            width: 118px;
                        }
                        &:nth-child(3){
                            width: 144px;
                        }
                        &:nth-child(4){
                            width: 156px;
                        }
                    }
                }
                &:nth-child(3){
                    .projects_status ul li{
                        &:nth-child(1){
                            width: 102px;
                        }
                        &:nth-child(2){
                            width: 74px;
                        }
                        &:nth-child(3){
                            width: 144px;
                        }
                    }
                }
            }
        }
    }
    &:last-child{
        margin-bottom: 0;
    }
}


body.db_header_active{
    .header{
        display: none;
    }
    // .main_content{
    //     padding-top: 0;
    //     .main_content_left {
    //         width: calc(100% - 330px);
    //     }
    //     .right-side-nav {
    //         top: 0px;
    //         right: 0;
    //         .menu_btn {
    //             display: none;
    //         }
    //     }
    // }
    .db_header{
        display: flex;
        padding: 20px 0;
        height: auto;
        transition: none;
        &.border{
            border-bottom:1px solid $borderColor;
        }
    }
    
    .tabs_wrapper{
        top: 0px;
        .active-scroll{
            height: calc(100vh - 125px) !important;
        }
    }
}


.db_page {
    width: 100%;
    .main_content_left {
        width: calc(100% - 330px);
    }
    .right-side-nav {
        top: 0px;
        right: 0;
        .menu_btn {
            display: none;
        }
    }
}