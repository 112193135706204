@import 'includes.scss';

.sidebar{background: $primaryColor;
    width: 60px;
    transition: all .3s ease-in-out;
    position: fixed;
    z-index: 99;
    overflow: hidden;
    .sidebar_cnt{
        padding: 20px 0 20px 17px;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        .logo a{
            display: flex;
            align-items: center;
            span {
                display: none;
                margin-left: 5px;
                img{
                    width: 80px;
                }
            }
        }
    }
    .menu{
        width: 100%;
        div:first-child{
            display: flex;
            align-items: center;
        }
        div:last-child div{
            background-color: $white !important;
        }
        li{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            margin-bottom:30px;
            a{
                display: flex;
                align-items: center;
                text-decoration: none;
            }
            &:last-child{
                margin-bottom: 0;
            }
            .menu_icon{
                width: 22px;
                height: 22px;
            }
            .menu_label{
                font-family: $primaryfontRegular;
                font-size: 14px;
                color: $white;
                padding-left: 10px;
                display: none;
                text-transform: capitalize;
                letter-spacing: 0.5px;
            }
            &.dashboard .menu_icon{background: url('../../images/menu/Dashboard-active.svg') no-repeat center center;}
            &.projects .menu_icon{background: url('../../images/menu/layers-active.svg') no-repeat center center;}
            &.channels .menu_icon{background: url('../../images/menu/chat-active.svg') no-repeat center center;}
            &.team .menu_icon{background: url('../../images/menu/customer-active.svg') no-repeat center center;}
            &.timesheet .menu_icon{background: url('../../images/menu/event-active.svg') no-repeat center center;}
            &.documents .menu_icon{background: url('../../images/menu/documents-folder-active.svg') no-repeat center center;}
            &.settings .menu_icon{background: url('../../images/menu/settings.svg') no-repeat center center;}
            &.logout .menu_icon{background: url('../../images/menu/logout.svg') no-repeat center center;}
        }
    }
    &:hover{
        width: 200px;
        .menu li .menu_label{display: block;}
        .sidebar_cnt .logo a span{
            display: flex;
        }
    }
}